<template>
  <section class="container">
    <div style="background-color: black">
      <Avatar size="small" seed-string="W H" color="FFCB37" />
      <Avatar size="medium" seed-string="W H" color="FFCB37" />
      <Avatar size="large" seed-string="W H" color="FFCB37" />
      <Avatar size="huge" seed-string="W H" color="FFCB37" />
      <Avatar size="insane" seed-string="W H" color="FFCB37" />
    </div>
    <span class="is-size-1 has-text-weight-light is-grad">
      This is a Header with 😁 😉
    </span>
    <div class="columns mobile">
      <div class="column is-offset-one-quarter is-half">
        <b-field label="Total Colors">
          <b-slider v-model="colors" :min="2" :max="32" />
          <b-input disabled :value="colors" />
        </b-field>
        <b-field label="Starting Hue">
          <b-slider v-model="hue" :min="0.0" :max="355.5" :step="0.5" />
          <b-input disabled :value="hue" />
        </b-field>
        <b-field label="Saturation">
          <b-slider v-model="saturation" :min="0.0" :max="100.0" :step="0.5" />
          <b-input disabled :value="saturation" />
        </b-field>
        <b-field label="Lightness">
          <b-slider v-model="lightness" :min="0.0" :max="100.0" :step="0.5" />
          <b-input disabled :value="lightness" />
        </b-field>
        <b-field label="text options">
          <b-checkbox v-model="darkText">Dark Text</b-checkbox>
          <b-checkbox v-model="boldText">Bold Text</b-checkbox>
        </b-field>
        <b-button label="Show/Hide Code" @click="showCode = !showCode" />
        <pre v-if="showCode">{{ code }}</pre>
      </div>
    </div>

    <div class="is-flex is-flex-wrap-wrap is-justify-content-space-around">
      <div
        v-for="i in items"
        :key="i.id"
        class="mx-2 my-2 px-4 py-4 is-size-3 elevation-2"
        :class="{
          'has-text-dark': darkText,
          'has-text-light': !darkText,
          'has-text-weight-bold': boldText
        }"
        :style="style(i)"
      >
        {{ i.hue }}°
        {{ i.rgb }}
      </div>
    </div>
  </section>
</template>

<script>
import Avatar from "@/components/common/Avatar.vue";

export default {
  name: "Test",
  components: {
    Avatar
  },
  data: () => ({
    showCode: false,
    darkText: false,
    boldText: false,
    colors: 16,
    saturation: 50.0,
    lightness: 50.0,
    hue: 0.0
  }),
  computed: {
    items() {
      let out = [];
      for (let i = 0; i < this.colors; i++) {
        const hue = (this.hue + (i * 360) / this.colors) % 360;
        out.push({
          hue: Math.round(hue),
          id: i,
          rgb: this.HSL2RGB(hue, this.saturation, this.lightness)
        });
      }
      return out;
    },
    code() {
      let out = {};
      this.items.forEach((i, idx) => (out[idx.toString(16)] = i.rgb));
      return out;
    }
  },
  methods: {
    HSL2RGB(h, s, l) {
      // normalize ranges
      h /= 360;
      s /= 100;
      l /= 100;
      var r, g, b;
      if (s === 0) {
        r = g = b = l; // achromatic
      } else {
        var hue2rgb = function hue2rgb(p, q, t) {
          if (t < 0) t += 1;
          if (t > 1) t -= 1;
          if (t < 1 / 6) return p + (q - p) * 6 * t;
          if (t < 1 / 2) return q;
          if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
          return p;
        };

        var q = l < 0.5 ? l * (1 + s) : l + s - l * s;
        var p = 2 * l - q;
        r = hue2rgb(p, q, h + 1 / 3);
        g = hue2rgb(p, q, h);
        b = hue2rgb(p, q, h - 1 / 3);
      }

      const hex = [
        Math.round(r * 255).toString(16),
        Math.round(g * 255).toString(16),
        Math.round(b * 255).toString(16)
      ];
      const out = hex.reduce((out, item) => {
        let hexed = item.toString(16);
        if (hexed.length < 2) {
          hexed = "0" + hexed;
        }
        return out + hexed;
      }, "#");
      //this.$log.debug("converting", h, s, l, "to RGB", out);
      return out;
    },
    style(node) {
      if (!node) return;
      const out = {
        "background-color": node.rgb,
        "flex-basis": "200px",
        "text-align": "center"
      };
      return out;
    }
  }
};
</script>

<style lang="scss">
.is-grad {
  background-size: 100%;
  background: linear-gradient(45deg, magenta, orange);
  background-clip: text;
  text-fill-color: transparent;
}
</style>
